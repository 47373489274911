<template>
  <v-form>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-select
          label="Номер коннектора"
          :items="connectors"
          v-model="params.connector_id"
          required
        ></v-select>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="params.client_phone"
          :items="items"
          :loading="isLoading"
          :search-input.sync="search"
          hide-selected
          label="Поиск клиента"
          placeholder="Начните печатать для поиска"
          prepend-icon="mdi-database-search"
          :error-messages="error.error_message"
          :error="error.error"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import axios from "axios";

export default {
  name: "StartTransaction",
  props: ["params", "error"],
  data: () => {
    return {
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      phone: null,
      search: null,
    };
  },
  created() {
    // this.$store.dispatch("client_phone_list");
  },
  watch: {
    search(val) {
      // Items have already been loaded
      if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      axios
        .post("/srv/v1/client/search", { search: val })
        .then((res) => {
          this.entries = res.data.result;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    connectors() {
      const connectors = [];
      for (let evse in this.$store.getters.POINT_INFO.evse) {
        for (let connector in this.$store.getters.POINT_INFO.evse[evse]
          .connectors) {
          connectors.push({
            text: this.$store.getters.POINT_INFO.evse[evse].connectors[
              connector
            ].number,
            value:
              this.$store.getters.POINT_INFO.evse[evse].connectors[connector]
                .id,
          });
        }
      }

      return connectors;
    },
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      return this.entries;
    },
    // client_phones() {
    //   return this.$store.getters.CLIENT_PHONES;
    // },
  },
};
</script>

<style scoped></style>
