<template>
  <v-form>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-select
          label="Тип перезагрузки"
          :items="select_items"
          v-model="params.reset_type"
        ></v-select>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "Reset",
  props: ["params"],
  data: () => {
    return {
      select_items: [
        {
          text: "Мягкая",
          value: "Soft",
        },
        {
          text: "Жесткая",
          value: "Hard",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
