<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >Отправить команду <b>{{ component }}</b> станции</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <component :is="component" :params="params" :error="error" />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="create"> Отправить </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="close"> Закрыть </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import EnableCommand from "./commands/EnableCommand";
import ResetCommand from "./commands/ResetCommand";
import PointInfoCommand from "./commands/PointInfoCommand";
import StartTransactionCommand from "./commands/StartTransactionCommand";
import StopTransactionCommand from "./commands/StopTransactionCommand";
import TransactionInfoCommand from "./commands/TransactionInfoCommand";
import TriggerMessageCommand from "./commands/TriggerMessageCommand";
import UnlockCommand from "./commands/UnlockCommand";
import ConfigCommand from "./commands/ConfigCommand";
import UpdateCommand from "./commands/UpdateCommand";
import StatusCommand from "./commands/StatusCommand";

export default {
  name: "PointCommandComponent",
  components: {
    Enable: EnableCommand,
    Reset: ResetCommand,
    PointInfo: PointInfoCommand,
    StartTransaction: StartTransactionCommand,
    StopTransaction: StopTransactionCommand,
    TransactionInfo: TransactionInfoCommand,
    TriggerMessage: TriggerMessageCommand,
    Unlock: UnlockCommand,
    Config: ConfigCommand,
    Update: UpdateCommand,
    Status: StatusCommand
  },
  props: ["component", "params", "error"],
  data: () => {
    return {};
  },
  computed: {
    dialog() {
      return this.$store.getters.POINT_COMMAND_DIALOG;
    },
  },
  methods: {
    create() {
      this.$emit("send_command");
    },
    close() {
      this.$store.dispatch("point_command_dialog", false);
    },
  },
};
</script>

<style scoped></style>
