<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="1000" @click:outside="close_add">
      <v-card class="pa-3">
        <div class="d-flex grow flex-wrap">
          <v-sheet
            elevation="6"
            class="mb-n6 success pa-4 has_heading"
            width="100%"
            height="100"
          >
            <div class="text-h5 font-weight-light white--text">
              {{ page_text }}
            </div>
            <div class="subtitle-1 font-weight-light white--text">
              * помечены обязательные поля
            </div>
          </v-sheet>
        </div>

        <v-form
          ref="form"
          v-model="valid"
          autocomplete="off"
          @submit.prevent=""
        >
          <v-container fluid>
            <v-col cols="">
              <v-text-field
                v-model="point.point_number"
                label="Номер зарядной станции*"
                min="100000"
                max="999999"
                :readonly="this.edit"
                counter="6"
                maxlength="6"
                hint="Выданный станции уникальный номер в системе"
                autocomplete="off"
                :rules="number_rule"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                v-model="point.model"
                autocomplete="off"
                label="Модель*"
                hint="Модель зарядной станции от производителя"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-select
                v-model="point.evse[0].power"
                :items="point_powers"
                label="Мощность станции"
                :rules="[required]"
              ></v-select>
            </v-col>
            <v-col cols="">
              <v-text-field
                v-model="point.vendor"
                label="Производитель*"
                hint="Название производителя"
                autocomplete="off"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                v-model="point.name"
                label="Название"
                hint="Удобное название станции"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                v-model="point.serial"
                label="Серийный номер*"
                hint="Серийный номер станции от производителя"
                autocomplete="off"
                :readonly="this.edit"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                v-model="point.city"
                hint="Город установки ЭЗС"
                autocomplete="off"
                label="Город*"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                v-model="point.address"
                hint="Точный адрес установки ЭЗС"
                autocomplete="off"
                label="Адрес*"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                v-model="point.password"
                type="password"
                label="Пароль*"
                hint="Пароль для ЭЗС"
                autocomplete="off"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                v-model="point.max_transactions"
                label="Одновременных зарядок"
                type="number"
                hint="Колличество одновременных зарядок на станции"
                autocomplete="off"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                :value="connectors_count"
                label="Коннекторы"
                type="number"
                hint="Колличество коннекторов на станции"
                autocomplete="off"
                :rules="[required]"
                @input="add_connector"
              ></v-text-field>
            </v-col>
            <template v-for="(connector, i) in this.point.evse[0].connectors">
              <v-col cols="" :key="i">
                <v-select
                  :items="connectors_types"
                  v-model="connector.connector_type"
                  :label="connector_text(i + 1)"
                  autocomplete="off"
                  :rules="[required]"
                ></v-select>
              </v-col>
            </template>
            <v-col cols="">
              <v-container>
                <v-row>
                  <v-col cols="12" class="pa-0">
                    <v-btn block @click="show_map"
                      >Выбрать на карте
                      <v-icon>mdi-map-marker</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pa-0"
                    ><v-text-field
                      label="Широта*"
                      v-model="point.latitude"
                      :rules="[required]"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" class="pa-0"
                    ><v-text-field
                      label="Долгота*"
                      v-model="point.longitude"
                      :rules="[required]"
                    ></v-text-field
                  ></v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="">
              <v-btn
                type="submit"
                block
                @click="create_point"
                color="primary"
                >{{ btn_text }}</v-btn
              >
            </v-col>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="map_dialog" open-delay="1000" eager>
      <v-card>
        <div ref="marker_map" id="map"></div>
        <v-card-actions>
          <v-btn color="warning " @click="close_map"> Закрыть </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="select"
            :disabled="location_not_selected()"
          >
            Выбрать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "AddPointTab",
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      map_dialog: false,
      valid: true,
      new_point: {
        point_number: null,
        name: "",
        model: "",
        password: "",
        city: "",
        address: "",
        serial: "",
        vendor: "",
        latitude: "",
        longitude: "",
        max_transactions: 1,
        evse: [{ power: "", connectors: [{ connector_type: "type2" }] }],
      },
      connectors: 1,
      connectors_types: ["type2", "chademo", "ccs2"],
      point_powers: [
        "7AC",
        "22AC",
        "50DC",
        "60DC",
        "80DC",
        "90DC",
        "120DC",
        "150DC",
        "180DC",
      ],
      required: (v) => !!v || "Обязателено для заполнения",
      number_rule: [
        (v) => !!v || "Обязателено для заполнения",
        (v) => (v && v > 100000) || "Номер не может быть меньше 100000",
        (v) => (v && v < 999999) || "Номер не может быть больше 999999",
      ],
    };
  },
  mounted() {
    // this.init_map();
    // this.connectors_count = this.point.evse[0].connectors.length;
    this.connectors = this.point.evse[0].connectors.length;
  },
  computed: {
    dialog() {
      return this.$store.getters.ADD_POINT_DIALOG;
    },
    page_text() {
      if (this.edit) {
        return "Редактирование станции";
      }
      return "Добавить зарядную станцию";
    },
    btn_text() {
      if (this.edit) {
        return "Сохранить";
      }
      return "Добавить";
    },
    point() {
      if (this.edit) {
        return this.$store.getters.POINT_INFO;
      }
      return this.new_point;
    },
    connectors_count() {
      return this.point.evse[0].connectors.length;
    },
  },
  methods: {
    init_map() {
      // eslint-disable-next-line no-unused-vars
      let marker = new window.google.maps.Marker({
        title: "Hello World!",
      });

      const center_position = {
        lat: 55.75532732384285,
        lng: 37.633314549373964,
      };
      // const myLatlng = new window.google.maps.LatLng(
      //   55.75532732384285,
      //   37.633314549373964
      // );
      const mapContainer = this.$refs.marker_map;
      const map = new window.google.maps.Map(mapContainer, {
        zoom: 12,
        center: center_position,
      });
      // Create the initial InfoWindow.
      let infoWindow = new window.google.maps.InfoWindow({
        content: "Выберите точку на карте",
        position: center_position,
      });

      infoWindow.open(map);
      // Configure the click listener.
      map.addListener("click", (mapsMouseEvent) => {
        // Close the current InfoWindow.
        infoWindow.close();
        // Create a new InfoWindow.
        // infoWindow = new window.google.maps.InfoWindow({
        //   position: mapsMouseEvent.latLng,
        // });
        const gps = mapsMouseEvent.latLng.toJSON();
        if (this.edit) {
          this.$store.getters.POINT_INFO.latitude = gps.lat;
          this.$store.getters.POINT_INFO.longitude = gps.lng;
        } else {
          this.new_point.latitude = gps.lat;
          this.new_point.longitude = gps.lng;
        }
        marker.setPosition(gps);
        marker.setMap(map);
        // infoWindow.setContent(
        //   JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)
        // );
        // infoWindow.open(map);
      });
    },
    show_map() {
      this.map_dialog = true;
      this.init_map();
    },

    validateField() {
      this.$refs.form.validate();
    },

    create_point() {
      this.validateField();

      if (!this.valid) {
        return;
      }
      const point = {
        name: this.point.name,
        model: this.point.model,
        password: this.point.password,
        address: this.point.address,
        city: this.point.city,
        serial: this.point.serial,
        vendor: this.point.vendor,
        point_number: this.point.point_number,
        evse: this.point.evse,
        max_transactions: this.point.max_transactions,
        gps: this.point.latitude + " " + this.point.longitude,
      };
      if (this.edit) {
        point.id = this.point.id;
        this.$store.dispatch("update_point", point).then(() => {
          this.close_add();
        });
      } else {
        this.$store.dispatch("create_point", point).then(() => {
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
          this.$emit("refresh");
          this.close_add();
        });
      }
    },
    connector_text(index) {
      return "Тип коннектора " + index;
    },
    add_connector(new_value) {
      if (this.connectors > new_value) {
        this.point.evse[0].connectors.splice(-1);
      }
      if (this.connectors < new_value) {
        this.point.evse[0].connectors.push({ connector_type: "type2" });
      }
      this.connectors = new_value;
    },
    close_add() {
      this.$store.dispatch("add_point_dialog", false);
    },
    close_map() {
      this.map_dialog = false;
    },
    select() {
      this.map_dialog = false;
    },
    location_not_selected() {
      return this.new_point.latitude === "" && this.new_point.longitude === "";
    },
  },
};
</script>

<style scoped lang="scss">
#map {
  height: 800px;
}
</style>
