<template>
  <v-form>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-select
          label="Тип сообщения"
          :items="select_items"
          v-model="params.message"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="12" v-if="connector_required">
        <v-select
            label="Номер коннектора"
            :items="connectors"
            v-model="params.connector_id"
            required
        ></v-select>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "TriggerMessage",
  props: ["params"],
  data: () => {
    return {
      select_items: [
        {
          text: "Heartbeat",
          value: 0,
        },
        {
          text: "BootNotification",
          value: 1,
        },
        {
          text: "MeterValues",
          value: 2,
        },
        {
          text: "StatusNotification",
          value: 3,
        },
        {
          text: "TransactionEvent",
          value: 4,
        },
        {
          text: "LogStatusNotification",
          value: 5,
        },
        {
          text: "FirmwareStatusNotification",
          value: 6,
        },
        {
          text: "PublishFirmwareStatusNotification",
          value: 7,
        },
      ],
    };
  },
  computed: {
    connectors() {
      const connectors = [
        {
          text: "0 (Станция)",
          value: 0,
        },
      ];
      for (let evse in this.$store.getters.POINT_INFO.evse) {
        for (let connector in this.$store.getters.POINT_INFO.evse[evse]
            .connectors) {
          connectors.push({
            text: this.$store.getters.POINT_INFO.evse[evse].connectors[
                connector
                ].number,
            value:
            this.$store.getters.POINT_INFO.evse[evse].connectors[connector]
                .id,
          });
        }
      }

      return connectors;
    },
    connector_required(){
      return [3].includes(this.params.message)
    }
  },
  methods: {

  }
};
</script>

<style scoped></style>
