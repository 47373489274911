import { render, staticRenderFns } from "./ConfigCommand.vue?vue&type=template&id=7015920c&scoped=true&"
import script from "./ConfigCommand.vue?vue&type=script&lang=js&"
export * from "./ConfigCommand.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7015920c",
  null
  
)

export default component.exports