<template>
  <v-form>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-select
          label="Действие"
          :items="select_items"
          v-model="params.enable"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-select
          label="Номер коннектора"
          :items="connectors"
          v-model="params.connector_id"
          required
        ></v-select>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "Enable",
  props: ["params"],
  data: () => {
    return {
      enable: true,
      connector_number: 0,
      select_items: [
        { text: "Выключить", value: false },
        { text: "Включить", value: true },
      ],
    };
  },
  computed: {
    connectors() {
      const connectors = [
        {
          text: "0 (Станция)",
          value: 0,
        },
      ];
      for (let evse in this.$store.getters.POINT_INFO.evse) {
        for (let connector in this.$store.getters.POINT_INFO.evse[evse]
          .connectors) {
          connectors.push({
            text: this.$store.getters.POINT_INFO.evse[evse].connectors[
              connector
            ].number,
            value:
              this.$store.getters.POINT_INFO.evse[evse].connectors[connector]
                .id,
          });
        }
      }

      return connectors;
    },
  },
  methods: {},
};
</script>

<style scoped></style>
