<template>
  <v-form>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-autocomplete
          v-model="params.transaction_id"
          :items="items"
          :loading="isLoading"
          :search-input.sync="search"
          hide-selected
          label="Транзакции"
          no-data-text="Нет запущенных сессий"
          placeholder="Транзакции"
          prepend-icon="mdi-database-search"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "StopTransaction",
  props: ["params"],
  data: () => {
    return {
      transactions: [],
      isLoading: false,
      transaction: null,
      search: null,
    };
  },
  watch: {
    search(val) {
      // Items have already been loaded
      if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      this.load_transactions(val);
    },
  },
  created() {
    this.load_transactions();
  },
  computed: {
    point_number() {
      return this.$store.getters.POINT_INFO.point_number;
    },
    items() {
      return this.$store.getters.TRANSACTIONS;
    },
  },
  methods: {
    load_transactions() {
      this.$store.dispatch("load_transaction", {
        point_number: this.point_number,
      });
    },
    destroyElement() {
      // Destroy method
      this.$destroy();
    },
  },
};
</script>

<style scoped></style>
