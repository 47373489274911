<template>
  <div class="">Config</div>
</template>

<script>
export default {
  name: "Config",
  props: ["params"],
};
</script>

<style scoped></style>
