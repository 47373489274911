<template>
  <v-form>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-text-field
            label="Версия прошивки"
        v-model="params.firmware"></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-text-field
            type="number"
            label="Количество файлов"
            v-model="params.files_count"></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "Update",
  props: ["params"],
  computed: {
  },
};
</script>

<style scoped></style>
